import { get, post, put } from './httpservices'
import { store } from '../store/store'
import { router } from '../router/index'
import { userprofile } from './authservices'

export async function getcompany_id() {
	if (store.state.auth.user_data.company_id === '') {
		await userprofile()
		return store.state.auth.user_data.company_id
	} else {
		return store.state.auth.user_data.company_id
	}
}

export async function getSetting() {
	let resp
	try {
		resp = await get('distributor/setting')
		let data = resp.data.data
		if (resp.data.data) {
			store.commit('distributorInfo', JSON.parse(JSON.stringify(data.distributor)))
			store.commit('companyInfo', JSON.parse(JSON.stringify(data.company)))
			store.commit('categoryInfo', JSON.parse(JSON.stringify(data.category)))
			store.commit('authorizeUserInfo', JSON.parse(JSON.stringify(data.authorize_user)))
			store.commit('deliveryZoneInfo', JSON.parse(JSON.stringify(data.delivery_zone)))
			store.commit('preferenceInfo', JSON.parse(JSON.stringify(data.preference)))
			store.commit('transactionHistory', JSON.parse(JSON.stringify(data.payment_transactions)))
			store.commit('bankInformation', JSON.parse(JSON.stringify(data.bank_information)))
		}
	} catch (e) {
		console.log(e)
		// router.push('/500');
		return e.response
	}
}

export async function googlePlaces(data) {
	let resp
	store.commit('background')
	try {
		resp = await post('google-places', data)
		return resp.data
	} catch (e) {
		console.log(e)
		// router.push('/500');
		return e.response
	}
}

export async function companyDetailUpdate(data) {
	let company_id = await getcompany_id()
	if (company_id) {
		let resp
		try {
			resp = await post('distributor/company/' + company_id, data)
			return resp.data
		} catch (e) {
			console.log(e)
			// router.push('/500');
			return e.response
		}
	}
}

export async function getCompanyDetail() {
	let company_id = await getcompany_id()
	if (company_id) {
		let resp
		try {
			resp = await get('distributor/company/' + company_id)
			return resp.data
		} catch (e) {
			console.log(e)
			// router.push('/500');
			return e.response
		}
	}
}

export async function updateCategory(data) {
	let company_id = await getcompany_id()
	if (company_id) {
		let resp
		try {
			resp = await put('distributor/category', data)
			return resp.data
		} catch (e) {
			console.log(e)
			// router.push('/500');
			return e.response
		}
	}
}

export async function updateBankInfo(data) {
	let company_id = await getcompany_id()
	if (company_id) {
		let resp
		try {
			resp = await put('distributor/company/account/' + company_id, data)
			return resp.data
		} catch (e) {
			console.log(e)
			// router.push('/500');
			return e.response
		}
	}
}

export async function addAuthorizeUser(data) {
	let company_id = await getcompany_id()
	if (company_id) {
		let resp
		try {
			resp = await put('distributor/add-authorizeuser/' + company_id, data)
			return resp.data
		} catch (e) {
			console.log(e)
			// router.push('/500');
			return e.response
		}
	}
}

export async function removeAuthorizeUser(user_id) {
	let company_id = await getcompany_id()
	if (company_id) {
		let resp
		try {
			resp = await put('distributor/remove-authorizeuser/' + user_id + '/' + company_id)
			return resp.data
		} catch (e) {
			console.log(e)
			// router.push('/500');
			return e.response
		}
	}
}

export async function addDeliveryZone(data) {
	let company_id = await getcompany_id()
	if (company_id) {
		let resp
		try {
			resp = await put('distributor/add-deliveryzone/' + company_id, data)
			return resp.data
		} catch (e) {
			console.log(e)
			// router.push('/500');
			return e.response
		}
	}
}
