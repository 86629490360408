<template>
	<div class="delivery-zone">
		<v-row no-gutters dense class="d-flex" style="height:inherit">
			<v-col cols="12" class="col-md-12 pt-8 my-pr">
				<v-card class="d-flex flex-column my-card">
					<v-row no-gutters dense class="d-flex align-start flex-grow-0 pb-8">
						<v-col cols="12">
							<p class="heading-sf20">Manage authorized delivery zones</p>
							<p
								class="simple-text pt-2"
								>Please create up to 5 delivery zones by identifying shipping costs based on the distance from your location.</p>
						</v-col>
					</v-row>
					<v-row no-gutters dense class="d-flex flex-grow-0 mx-auto mx-sm-0">
						<v-form v-model="valid" ref="form" lazy-validation>
							<v-row>
								<transition-group name="add-remove" tag="div">
									<div
										v-for="delivery in delivery_data"
										v-bind:key="delivery.zone_name"
										class="pr-sm-6 pb-6 add-remove-item"
									>
										<app-delivery-zone :value="delivery">
											<div slot="delivery-zone-delete">
												<div v-if="delivery.zone_name != 'Zone 1'">
													<img
														v-if="$store.state.setting.distributor.role === 'A'"
														:src="$store.state.icons.icons['Dustbin']"
														@click="deleteZone(delivery)"
														class="H-18 text-right"
														alt
													/>
												</div>
											</div>
										</app-delivery-zone>
									</div>
								</transition-group>
							</v-row>
						</v-form>
					</v-row>
					<v-row no-gutters dense align="stretch" class="d-flex">
						<v-col
							v-if="error_message != ''"
							class="d-sm-flex align-center mt-auto p-0 pb-4 custom-error"
							cols="12"
						>{{ this.error_message }}</v-col>
						<v-col cols="12" sm="6" class="d-flex justify-start mt-auto mb-sm-0 mb-3">
							<v-btn
								v-if="$store.state.setting.distributor.role === 'A' && this.deliverys.length < 5"
								class="my-outline-btn mr-auto mr-5"
								depressed
								outlined
								@click="addZone"
							>Add delivery zone</v-btn>
						</v-col>
						<v-col cols="12" sm="6" class="d-flex justify-end mt-auto">
							<v-btn
								class="my-auto-btn my_elevation"
								id="my_elevation"
								@click="deliveryZones()"
								:disabled="!valid || $store.state.setting.distributor.role === 'AU'"
								depressed
							>Save changes</v-btn>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
const DeliveryZone = () => import("../../mycomponents/Deliveryzone");
import { addDeliveryZone } from "../../services/settingservice";
export default {
	components: {
		AppDeliveryZone: DeliveryZone
	},

	data() {
		return {
			active_tab: false,
			show: true,
			valid: true,
			error_message: "",
			deliverys: []
		};
	},

	created() {
		this.$store.watch(
			state => {
				return state.auth.error;
			},
			() => {
				if (this.active_tab === true) {
					this.error_message = this.$store.state.auth.error;
				}
			},
			{ deep: true }
		);

		this.$store.watch(
			state => {
				return state.setting.delivery_zone, state.setting.setting_tab;
			},
			() => {
				if (
					this.$store.state.setting.setting_tab.tab ===
					"delivery_zone"
				) {
					let data = this.$store.state.setting.delivery_zone;
					this.myDelivery(data);
				}
			},
			{ deep: true }
		);
	},

	async mounted() {
		let data = this.$store.state.setting.delivery_zone;
		this.myDelivery(data);
	},

	computed: {
		Show() {
			return (this.show = !this.valid ? false : true);
		},

		delivery_data() {
			this.deliverys.forEach((item, index) => {
				item.zone_name = "Zone " + (index + 1);
			});

			return this.deliverys;
		}
	},

	methods: {
		myDelivery(data) {
			this.deliverys = [];
			if (data.length > 0) {
				data.forEach(item => {
					let count = this.deliverys.length + 1;
					let sd;
					if (item.distance === 5) {
						sd = 0;
					} else if (item.distance === 15) {
						sd = 1;
					} else if (item.distance === 25) {
						sd = 2;
					} else if (item.distance === 45) {
						sd = 3;
					} else if (item.distance === 75) {
						sd = 4;
					}

					var obj = {
						zone_id: item.zone_id,
						zone_name: item.zone_name,
						distance: item.distance,
						distance_select: sd,
						shipping_price: item.shipping_price
					};
					this.deliverys.push(obj);
				});
			} else {
				let count = this.deliverys.length + 1;
				var obj = {
					zone_id: 0,
					zone_name: "Zone " + count,
					distance: "",
					distance_select: 0,
					shipping_price: 200
				};
				this.deliverys.push(obj);
			}
		},

		addZone() {
			let count = this.deliverys.length + 1;
			var obj = {
				zone_id: 0,
				zone_name: "Zone " + count,
				distance: "",
				distance_select: 0,
				shipping_price: 0
			};
			this.deliverys.push(obj);
			this.$store.state.setting.delivery_zone.push(
				JSON.parse(JSON.stringify(obj))
			);
		},

		deleteZone(delivery) {
			this.deliverys.splice(this.deliverys.indexOf(delivery), 1);
			this.$store.state.setting.delivery_zone.splice(
				this.deliverys.indexOf(delivery),
				1
			);
		},

		async deliveryZones() {
			if (this.$refs.form.validate()) {
				this.error_message = "";
				this.$store.commit("errorMessage", "");
				this.active_tab = true;

				this.deliverys.forEach(item => {
					if (item.distance_select === 0) {
						item.distance = 5;
					} else if (item.distance_select === 1) {
						item.distance = 15;
					} else if (item.distance_select === 2) {
						item.distance = 25;
					} else if (item.distance_select === 3) {
						item.distance = 45;
					} else if (item.distance_select === 4) {
						item.distance = 75;
					}
				});

				let distance = [5, 15, 25, 45, 75];
				distance.forEach(item => {
					let data = this.deliverys.filter(delivery => {
						if (delivery.distance === item) {
							return delivery;
						}
					});

					if (data.length > 1) {
						let message =
							"Your delivery zone " +
							data[0].zone_name +
							" and " +
							data[1].zone_name +
							" distance is overlapped";
						this.error_message = message;
					}
				});

				if (this.error_message === "") {
					let data = await addDeliveryZone({
						deliverys: this.deliverys
					});

					if (!data.data) {
						this.error_message = this.$store.state.auth.error;
					} else {
						this.$store.commit("deliveryZoneInfo", data.data);
						this.myDelivery(data.data);
						this.active_tab = false;
					}
				}
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.delivery-zone {
	height: 100%;
}
</style>